import { ANALYTICS_EVENT_TYPE, ANALYTICS_SUPPORT_EVENT_TYPE, SEVERITY } from '@config/logging';
import { isBuyCoinsTryEvent } from '@helpers/logging/analytics/buy-coins-try-event';
import { isBuyPremiumInvoicedEvent } from '@helpers/logging/analytics/buy-premium-invoiced-event';
import { isBuyPremiumTryEvent } from '@helpers/logging/analytics/buy-premium-try-event';
import { isAnalyticsModalViewCalendarData, isModalViewEvent } from '@helpers/logging/analytics/modal-view-event';
import { isAnalyticsPageViewRegularPageData, isAnalyticsPageViewWithMemberPageData, isPageViewEvent } from '@helpers/logging/analytics/page-view-event';
import { isSessionEvent } from '@helpers/logging/analytics/session-event';
import { isT1NotificationLinkVisitedEvent } from '@helpers/logging/analytics/t1-notification-link-visited-event';
import { isT5NotificationLinkVisitedEvent } from '@helpers/logging/analytics/t5-notification-link-visited-event';
import { isSendUIClickEvent } from '@helpers/logging/analytics/ui-click-event';
import { isSendVideoViewProgressEvent } from '@helpers/logging/analytics/video-view-progress-event';
import { isVisitEvent } from '@helpers/logging/analytics/visit-event';
import { isServer } from '@helpers/misc';
import { IAnalyticsEvent } from '@logging/logging-events';
import { analyticsSendEvent, analyticsSendVisit } from '@services/analytics';
import { analyticsSendAmplitudeSession } from '@services/analytics/analytics-send-amplitude-session';
import { analyticsSupportSendEvent } from '@services/analytics/analytics-support-send-event';
import { ICustomAnalyticsEventHandler } from './types';
export class CustomAnalyticsEventHandler<D> implements ICustomAnalyticsEventHandler<D> {
  public severities = [SEVERITY.ANALYTICS];
  public async handle<D>(event: IAnalyticsEvent<D>): Promise<void> {
    if (isServer()) {
      return;
    }
    if (isVisitEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendVisit(data).promise;
    }
    if (isSessionEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendAmplitudeSession(data).promise;
    }
    if (isBuyCoinsTryEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendEvent({
        eventType: ANALYTICS_EVENT_TYPE.BUY_COINS_TRY,
        data
      }).promise;
    }
    if (isBuyPremiumTryEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendEvent({
        eventType: ANALYTICS_EVENT_TYPE.BUY_PREMIUM_TRY,
        data
      }).promise;
    }
    if (isBuyPremiumInvoicedEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendEvent({
        eventType: ANALYTICS_EVENT_TYPE.BUY_PREMIUM_INVOICED,
        data
      }).promise;
    }
    if (isT1NotificationLinkVisitedEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSupportSendEvent({
        eventType: ANALYTICS_SUPPORT_EVENT_TYPE.T1_NOTIFICATION_LINK_VISITED,
        data
      }).promise;
    }
    if (isT5NotificationLinkVisitedEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSupportSendEvent({
        eventType: ANALYTICS_SUPPORT_EVENT_TYPE.T5_NOTIFICATION_LINK_VISITED,
        data
      }).promise;
    }
    if (isSendUIClickEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendEvent({
        eventType: ANALYTICS_EVENT_TYPE.UI_CLICKED,
        data
      }).promise;
    }
    if (isSendVideoViewProgressEvent(event)) {
      const {
        data
      } = event.payload;
      await analyticsSendEvent({
        eventType: ANALYTICS_EVENT_TYPE.VIDEO_VIEW_PROGRESS,
        data
      }).promise;
    }
    if (isPageViewEvent(event)) {
      const {
        data
      } = event.payload;
      if (isAnalyticsPageViewRegularPageData(data)) {
        await analyticsSendEvent({
          eventType: ANALYTICS_EVENT_TYPE.PAGE_VIEW,
          data
        }).promise;
      }
      if (isAnalyticsPageViewWithMemberPageData(data)) {
        await analyticsSendEvent({
          eventType: ANALYTICS_EVENT_TYPE.PAGE_VIEW,
          data
        }).promise;
      }
    }
    if (isModalViewEvent(event)) {
      const {
        data
      } = event.payload;
      if (isAnalyticsModalViewCalendarData(data)) {
        await analyticsSendEvent({
          eventType: ANALYTICS_EVENT_TYPE.MODAL_VIEW,
          data
        }).promise;
      }
    }
  }
}